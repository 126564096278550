/* global heap */
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import Logo from '../Logo';
import Flexor from '../Flexor';
import H3 from '../Text/H3';
import Input, { InputStyles } from '../Input';
import Button from '../Button';
import Spinner from '../Spinner';
import { addUserToDrop } from '../../helpers/API';
import Utility from '../Text/Utility';

export const Container = styled.form`
  width: 100%;
  max-width: 335px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const style = {
  style: {
    base: {
      'backgroundColor': 'transparent',
      'color': 'black',
      'fontFamily': 'Manrope, sans-serif',
      'fontWeight': 300,
      'fontSize': '16px',
      '::placeholder': { color: '#999999' },
    },
  },
};

function PaymentPage({
  next, paymentSecret, selectedDrop, back, amountOfEntries,
}) {
  const [view, setView] = useState('payment');
  const [name, setName] = useState(null);
  const stripe = useStripe();
  const elements = useElements({ cssSrc: 'https://fonts.googleapis.com/css2?family=Manrope' });
  const [stripeComplete, setStripeComplete] = useState(false);
  const [email, setEmail] = useState(null);
  const handleSubmit = useCallback(async () => {
    setView('loading');
    heap.addUserProperties({ 'email': email, 'Name': name });

    const payload = await stripe.confirmCardPayment(paymentSecret, { receipt_email: email, payment_method: { card: elements.getElement(CardElement) } });

    if (payload.error) {
      alert(`Error processing payment ${payload.error.message}`);
      setView('payment');
    } else {
      const { paymentIntent } = payload;
      const res = await addUserToDrop(selectedDrop.id, paymentIntent.id, paymentIntent.amount, email, selectedDrop?.items[0]?.name, amountOfEntries);

      if (res === 'OK') {
        next();
      }
    }
  }, [next, elements, stripe, paymentSecret, selectedDrop, email, amountOfEntries, name]);

  return (
    <Container onSubmit={(e) => {
      e.preventDefault();
      handleSubmit();
    }}
    >
      <Utility onClick={back} style={{
        color:'black', textTransform: 'unset', cursor:'pointer', position:'absolute', left: 0, top: 20,
      }}
      >Back</Utility>
      <Spinner show={view === 'loading'} />
      <Logo style={{ marginTop: 52 }} />
      <Flexor flex="0 1 150px" />
      <H3 style={{
        color: 'black', textAlign: 'left', marginBottom: 8,
      }}
      >Enter Payment Info</H3>
      <Input autoComplete="name" required max={1} style={{ marginBottom: 12 }} value={name} update={setName} label="Name on card" />
      <InputStyles showOutline={stripeComplete} style={{
        marginBottom: 12,
        flexDirection:'column', alignItems:'unset', padding: '0 15px', width: 'calc(100% - 30px)',
      }}
      >
        <CardElement onChange={(e) => {
          setStripeComplete(e.complete);
        }} options={style}
        />
      </InputStyles>
      <Input max={1} autoComplete="email" required style={{ marginBottom: 12 }} value={email} update={setEmail} label="Email" />
      <Button type="submit" label="Let's go" />
    </Container>
  );
}

export default PaymentPage;