import styled from 'styled-components';

export const Container = styled.div`
  font-family: Manrope;
  font-weight: 800;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: left;
  color: black;
  text-align: center;
  width: 100%;
`;