import React from 'react';
import { connect } from 'react-redux';
import Layout from '../components/Layout';
import Component from '../components/Drops';

function Drops(props) {
  return (
    <Layout>
      <Component {...props} />
    </Layout>
  );
}

const mapStateToProps = (state) => ({ ...state.dropsReducer, ...state.userReducer });

export default connect(mapStateToProps)(Drops);
