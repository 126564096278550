import styled from 'styled-components';

export const Container = styled.div`
  font-family: Manrope;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  text-align: left;
  color: black;
  text-align: center;
`;