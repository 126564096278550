import React, { useCallback } from 'react';
import styled from 'styled-components';
import Logo from '../Logo';
import Divider from '../Divider';
import H3 from '../Text/H3';
import Utility from '../Text/Utility';
import Navigation from '../Text/Navigation';
import Column from '../Column';
import Row from '../Row';

import Spinner from '../Spinner';
import { useHourTimer, formatAmount } from '../helpers';
const Container = styled.div`
  width: 100%;
  max-height: none;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 52px;
  position: relative;
`;

function SelectDrop({
  setSelectedDrop, next, drops,
}) {
  const handleSelect = useCallback((dropId) => {
    const selected = drops.find(({ id }) => id == dropId);
    setSelectedDrop(selected);
    next(0);
  }, [setSelectedDrop, next, drops]);

  if (!drops.length){
    return <Spinner />;
  }
  return (
    <Container>
      <Logo style={{ marginTop: 52, marginBottom: 14 }} />
      <Divider />
      <H3 style={{
        marginTop: 24, marginBottom: 12, color: 'black',
      }}
      >Pick a drop</H3>
      <Column >
        {
          drops.map((item) => <Item select={handleSelect} key={item.id} {...item} />)
        }
      </Column>
    </Container>
  );
}

const ItemContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 0 24px;
  justify-content: flex-start;
  margin: auto;
  margin-bottom: 32px;
  :last-child {
    margin-bottom: 52px;
  }
  flex-shrink: 0;
  cursor: pointer;
  width: calc(100% - 48px);
  max-width: 327px;
  border-radius: 12px;
  pointer-events: ${props => props.active ? 'all' : 'none'};
`;

function Item({
  items, id, date, select, dropName,
}) {
  const {
    image, price, name,
  } = items[0];
  const countdownDate = useHourTimer(date);
  return (
    <ItemContainer active={!!date} onClick={() => select(id)}>
      <Row style={{ justifyContent:'space-between' }}>
        <Column>
          <Utility style={{ textAlign: 'left', color:'black' }}>{name}</Utility>
          <Navigation style={{ textAlign: 'left', color:'grey' }}>{dropName}</Navigation>
          <Navigation style={{ textAlign: 'left', color:'grey' }}>{countdownDate}</Navigation>
        </Column>
        <Utility style={{ fontSize: 18, color: 'black' }}>${formatAmount(price / 100)}</Utility>
      </Row>
      <img style={{
        margin:'auto',
        marginTop: 15, height: 248, width: '100%', objectFit: 'cove', border: '1px #f5f5f5 solid', borderRadius:18,
      }} src={image}
      />
    </ItemContainer>
  );
}

export default SelectDrop;
