import styled from 'styled-components';

export const Container = styled.div`
  font-size: 27px;
  line-height: 30px;
  letter-spacing: -0.3px;
  color: ${props => (props.color ? props.color : '#101011')};
  font-family: ${props =>
    props.bold
      ? `PublicSans-Bold`
      : props.semibold
      ? 'PublicSans-SemiBold'
      : 'PublicSans-Bold'};
  text-align: ${props =>
    props.right ? 'right' : props.left ? 'left' : undefined};
`;
