import React, {
  useCallback, useState, useRef, useContext,
} from 'react';
import styled from 'styled-components';
import Logo from '../Logo';
import Flexor from '../Flexor';
import Column from '../Column';
import Button from '../Button';
import H3 from '../Text/H3';
import { formatAmount } from '../helpers';
import Utility from '../Text/Utility';
import Navigation from '../Text/Navigation';
import { AppContext } from '../AppProvider';
import CheckIcon from './checkmark.svg';

export const Container = styled.div`
  width: 100%;
  max-width: 335px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

function SelectSubscription({
  next, setPrice, back, amount, setAmountOfEntries,
}) {
  const { current:options } = useRef([{
    id: '0',
    entries: 1,
    label:'1 ticket',
    amount: amount,
  },
  {
    id: '1',
    entries: 3,
    label:'3 tickets',
    amount: amount * 3 - (amount * .20),
  },
  {
    id: '2',
    entries: 5,
    label:'5 tickets',
    amount: amount * 5 - (amount * .40),
  },
  ]);
  const { actions: { showModal } } = useContext(AppContext);
  const [selected, setSelected] = useState(options[0].id);

  const handleSubmit = useCallback(() => {
    const selectedOption = options.find(({ id }) => id === selected);
    setPrice(selectedOption.amount);
    setAmountOfEntries(selectedOption.entries);
    next();
  }, [selected, setPrice, next, options, setAmountOfEntries]);
  return (
    <Container>
      <Utility onClick={back} style={{
        color:'black', textTransform: 'unset', cursor:'pointer', position:'absolute', left: 0, top: 20,
      }}
      >Back</Utility>
      <Logo style={{ marginTop: 52 }} />
      <Flexor flex="0 1 150px" />
      <H3 style={{
        color: 'black', textAlign: 'left', marginBottom: 8,
      }}
      >Select option</H3>
      <OptionPicker options={options} selected={selected} setSelected={setSelected} />
      <Navigation onClick={() => showModal(<Navigation style={{ color: '#747474', padding: 30 }}>
        To have a free entry into a drop of your choice
you have to send a postcard of the address
of your residence, drop that you are interested
in entering. Also your phone number that you
are wishing to be contacted. You will not have
better chances by submitting multiple free
entries. Send the postcard to P.O. BOX 6402, Norco CA 92280</Navigation>)} style={{ color: '#747474', textAlign: 'right' }}
      >Free submission info</Navigation>
      <Button onClick={handleSubmit} label="Next" />
    </Container>
  );
}


function OptionPicker({
  setSelected, selected, options,
}) {
  return (<Column>
    {
      options.map((option) => <Option select={() => setSelected(option.id)} selected={selected === option.id} key={option.id} {...option} />)
    }
  </Column>);
}

const Item = styled.div`
  height: 56px;
  width: 100%;
  border: ${props => props.selected ? '1px solid black' : '1px solid #747474'};
  background-color: ${props => props.selected ? '#ECECEC' : 'none'};
  border-radius: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  div:last-child {
    margin-left: auto;
    margin-right: 16px;
  }
`;

function Option({
  selected, select, label, amount,
}) {
  return (
    <Item onClick={select} selected={selected}>
      <RadioButton style={{ marginLeft: 16, marginRight: 8 }} selected={selected} />
      <div style={{ color: '#747474' }}>{label}</div>
      <div>${formatAmount(amount / 100)}</div>
    </Item>
  );
}

const RadioButtonContainer = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.selected ? 'black' : 'none'};
`;

function RadioButton({ selected, style }) {
  return (
    <RadioButtonContainer style={style} selected={selected}>
      {selected && <img src={CheckIcon} />}
    </RadioButtonContainer>
  );
}

export default SelectSubscription;
