import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import H4 from '../Text/H4';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;


function Link({
  label, style, color, link = () => {},
}) {
  return (
    <Container onClick={ typeof(link) === 'string' ? () => navigate(link) : () => link()} style={style}>
      <H4 style={{ color }}>{label}</H4>
    </Container>
  );
}

export default Link;
