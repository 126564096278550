import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 1px;
  width: 100%;
`;

function Divider({ color = '#454545' }) {
  return (
    <Container style={{ backgroundColor: color }} />
  );
}

export default Divider;
