import React from 'react';
import styled from 'styled-components';
import Flexor from '../Flexor';
import H3 from '../Text/H3';
import H1 from '../Text/H1';
import Body from '../Text/Body';
import Button from '../Button';
import Link from '../Link';
import Utility from '../Text/Utility';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;


function Complete({
  setPage, back, next,
}) {
  return (
    <Container>
      <Utility onClick={back} style={{
        textTransform: 'unset', cursor:'pointer', position:'absolute', left: 20, top: 20,
      }}
      >Back</Utility>
      <Flexor flex="0 0 238px" />
      <H1 style={{ textAlign: 'center', fontSize:70 }}>🤑</H1>
      <Flexor flex="0 1 42px" />
      <H3 style={{ marginBottom: 6 }}>You're in</H3>
      <Body style={{
        color:'white', width: 169, margin: 'auto',
      }}
      >We’ll text you the day before the item drops.</Body>
      <Flexor flex="1 0 100px" />
      <Button onClick={next} color="black" style={{
        margin:'auto',
        width: 'calc(100% - 48px)', marginBottom: 29, backgroundColor: 'white',
      }} label="GET A FREE DROP"
      />
      <Link link={() => setPage(0)} color="white" label="RETURN TO HOME" />
      <Flexor flex="0 1 33px" />
    </Container>
  );
}

export default Complete;