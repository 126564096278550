import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';
import styled from 'styled-components';
import Logo from '../Logo';
import Flexor from '../Flexor';
import Input from '../Input';
import H3 from '../Text/H3';
import Button from '../Button';
import Spinner from '../Spinner';
import { sendVerificationCode, checkVerificationCode } from '../../helpers/API';
import { signInWithToken } from '../../helpers/Firebase';
import { usePhoneNumberMask } from '../helpers';
import Utility from '../Text/Utility';

const Container = styled.form`
  width: 100%;
  max-width: 335px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

function PhoneAuth({
  submit, back, buttonText ='Submit',
}) {
  const [view, setView] = useState('create');
  const [phoneNumber, setPhoneNumber] = useState(null);
  const phoneNumberMasked = usePhoneNumberMask(phoneNumber);
  const handleSendCode = useCallback(async () => {
    setView('loading');
    await sendVerificationCode(phoneNumber);
    setView('auth');
  }, [phoneNumber]);

  const handleVerifyCode = useCallback(async (code) => {
    setView('loading');

    try {
      const loginToken = await checkVerificationCode(phoneNumber, code);

      const user = await signInWithToken(loginToken);

      if (user) {
        submit(user);
      }
    } catch (e) {
      if (e.code === 'auth/phone-number-already-exists') {
        alert('You already have a SubDrop account! Please login');
      } else {
        alert(e.message || e);
        setView('auth');
      }
    }
  }, [phoneNumber, submit]);

  let content;

  if (view === 'loading') {
    return <Spinner />;
  } else if (view === 'auth') {
    content = (
      <>
        <Logo style={{ marginTop: 52 }} />
        <Flexor flex="0 1 150px" />
        <H3 style={{
          color: 'black', textAlign: 'left', marginBottom: 8,
        }}
        >Enter the code we sent to ...{phoneNumber.slice(-4)}</H3>
        <CodeInput submit={handleVerifyCode} />
        <Button style={{ marginTop: 32 }} onClick={handleSendCode} small label="Resend code" />
      </>
    );
  } else {
    content = (
      <>
        <Logo style={{ marginTop: 52 }} />
        <Flexor flex="0 1 150px" />
        <H3 style={{
          color: 'black', textAlign: 'left', marginBottom: 8,
        }}
        >Enter Your Phone Number</H3>
        <Input required max={12} autoComplete="phone" type="tel" update={setPhoneNumber} value={phoneNumberMasked} label="Enter your phone #" />
        <Flexor flex="0 1 20px" />
        <Button type="submit" label={buttonText} />
      </>
    );
  }
  return (
    <Container onSubmit={(e) => {
      e.preventDefault();
      handleSendCode();
      return false;
    }}
    >
      {back && <Utility onClick={back} style={{
        color:'black', textTransform: 'unset', cursor:'pointer', position:'absolute', left: 0, top: 20,
      }}
      >Back</Utility>}
      {content}
    </Container>
  );
}

const CodeInputContainer = styled.div`
  display: flex;
  max-width: 335px;
  width: 100%;
  justify-content: space-between;
`;

const Pad = styled.input`
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #ECECEC;
  height: 56px;
  width: 46px;
  text-align: center;
`;

function CodeInput({ submit }) {
  const [code, setCode] = useState([]);
  const codeRef = useRef(null);

  function backspaceListener(e) {
    if (e.keyCode === 8) {
      setTimeout(() => {
        e.target.previousSibling?.focus();
      });
    }
  }

  const currentRef = codeRef.current;

  useEffect(() => {
    currentRef?.addEventListener('keydown', backspaceListener);

    return () => {
      currentRef?.removeEventListener('keydown', backspaceListener);
    };
  }, [currentRef]);

  function handleSetCode(e, i, value) {
    value = value?.slice(-1);
    const _code = code.slice(0);
    _code[i] = value;
    setCode(_code);

    if (i < 5 && value) {
      e.target.nextElementSibling.focus();
    } else if (_code.length === 6) {
      submit(_code.join(''));
    }
  }
  return (
    <CodeInputContainer ref={codeRef}>
      {
        Array(6).fill(0).map((_, index) => (
          <Pad max={1} key={index} value={code[index]} onChange={(e) => handleSetCode(e, index, e.target.value)} />))
      }
    </CodeInputContainer>
  );
}

export default PhoneAuth;
