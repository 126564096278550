import React, {
  useState, useEffect, useCallback,
} from 'react';
import styled from 'styled-components';
import { getPaymentSecret } from '../../helpers/API';
import SelectDrop from '../SelectDrop';
import PaymentPage from '../PaymentPage';
import PhoneAuth from '../PhoneAuth';
import Complete from '../Complete';
import Share from '../Share';
import SelectSubscription from '../SelectSubscription';
import Menu from '../Menu';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin: auto;
  justify-content: center;
  position: relative;
  background-color: ${props => props.backgroundColor};
  @media screen and (min-width: 500px){
    max-height: 700px;
  }
`;

function Drops({ drops, user }) {
  const [page, setPage] = useState(0);
  const [selectedDrop, setSelectedDrop] = useState(null);
  const [paymentSecret, setPaymentSecret] = useState(null);
  const [price, setPrice] = useState(null);
  const [amountOfEntries, setAmountOfEntries] = useState(null);
  let content;

  const handleUpdateSelectedDrop = useCallback(async () => {
    if (selectedDrop && selectedDrop.id && price) {
      const _paymentSecret = await getPaymentSecret(price);
      setPaymentSecret(_paymentSecret);
    }
  }, [selectedDrop, price]);

  useEffect(() => {
    handleUpdateSelectedDrop();
  }, [handleUpdateSelectedDrop]);

  const back = useCallback(() => {
    setPage(page - 1);
  }, [page]);

  const next = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  let backgroundColor = 'white';
  const loggedIn = user && !user.isAnonymous;

  switch (page) {
  case 0: content = <SelectDrop drops={drops} next={next} setSelectedDrop={setSelectedDrop} />;
    break;
  case 1: {
    if (!loggedIn) {
      content = <PhoneAuth back={back} submit={next} />;
      break;
    }
  }
  // eslint-disable-next-line no-fallthrough
  case 2 : content = <SelectSubscription amount={selectedDrop?.items[0].price} back={() => {
    if (loggedIn) {
      setPage(0);
    } else {
      back();
    }
  }} next={
    () => {
      if (loggedIn) {
        setPage(3);
      } else {
        next();
      }
    }
  } setPrice={setPrice} setAmountOfEntries={setAmountOfEntries}
  />;
    break;
  case 3 : content = <PaymentPage back={back} paymentSecret={paymentSecret} next={next} selectedDrop={selectedDrop} amountOfEntries={amountOfEntries} />;
    break;
  case 4: content = <Complete back={back} next={next} setPage={setPage} />;
    backgroundColor = 'black';
    break;
  case 5: content = <Share back={back} setPage={setPage} />;
    backgroundColor = 'black';
    break;
  }
  return (
    <Container backgroundColor={backgroundColor}>
      <Menu />
      {content}
    </Container>
  );
}

export default Drops;
